<div mat-dialog-title>{{ i18n.get('geojsonLayerSettings') }}</div>
<div mat-dialog-content>
  <fieldset class="like-mat-form-field-outline">
    <legend>{{ i18n.get('mapLayerType') }}</legend>
    <mat-radio-group [(ngModel)]="layer.type">
      <mat-radio-button value="geojson">GeoJSON</mat-radio-button>
      <mat-radio-button value="csv">CSV</mat-radio-button>
    </mat-radio-group>
  </fieldset>
  <mat-form-field appearance="outline" subscriptSizing="dynamic">
    <mat-label>{{ i18n.get('label') }}</mat-label>
    <input matInput type="text" [(ngModel)]="layer.label" />
  </mat-form-field>
  <mat-form-field appearance="outline" subscriptSizing="dynamic">
    <mat-label>{{ i18n.get('layerSourceUrl') }}</mat-label>
    <input matInput type="url" [(ngModel)]="sourceUrl" />
  </mat-form-field>
  @if (layer.type === 'csv') {
    <div>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>{{ i18n.get('delimiter') }}</mat-label>
        <input matInput type="text" maxlength="1" [(ngModel)]="layer.delimiter" />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>{{ i18n.get('fieldX') }}</mat-label>
        <input matInput type="text" [(ngModel)]="layer.fieldX" />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>{{ i18n.get('fieldY') }}</mat-label>
        <input matInput type="text" [(ngModel)]="layer.fieldY" />
      </mat-form-field>
      <mat-form-field appearance="outline" subscriptSizing="dynamic">
        <mat-label>{{ i18n.get('dataProjection') }}</mat-label>
        <input matInput type="text" [(ngModel)]="layer.dataProjection" />
        <div class="infoText">
          swiss: 'EPSG:2056', GPS: 'EPSG:4326', mercator: 'EPSG:3857' <a target="_blank" href="https://epsg.io/">epsg.io</a>
        </div>
      </mat-form-field>
      <fieldset class="like-mat-form-field-outline">
        <legend>{{ i18n.get('filterRegExPattern') }}</legend>
        <div class="infoText">
          {{ i18n.get('filterRegExPatternInfo') }}<br /><code [innerText]="'(val1|val2|prefix.*|.*suffix)'"></code>
        </div>
        <div class="infoText">{{ i18n.get('filterRegExPatternInfo2') }}<br /><code [innerText]="'.*(?<!val1)(?<!val2)'"></code></div>
        <div class="infoText">{{ i18n.get('searchRegExPatternFullMatch') }} <code>^...$</code></div>
        @if (layer.filterRegExPattern) {
          <div class="regexTitle">
            <div class="attr-filter-field">{{ i18n.get('Field') }}</div>
            <div class="attr-filter-regex">RegEx</div>
            <div class="attr-filter-options">{{ i18n.get('searchRegExPatternOptionsTitle') }}</div>
          </div>
          @for (regex of layer.filterRegExPattern; track regex; let i = $index) {
            <div class="filterRegExPattern">
              <input class="attr-filter-field" matInput type="text" [(ngModel)]="regex[0]" />
              <input
                class="attr-filter-regex"
                matInput
                [appRegexValidator]="regex[2]"
                #validInfo="ngModel"
                type="text"
                [(ngModel)]="regex[1]"
              />
              <input
                class="attr-filter-options"
                matInput
                type="text"
                [(ngModel)]="regex[2]"
                (input)="deferredRevalidate(validInfo)"
                (blur)="validInfo.control.updateValueAndValidity()"
              />
              <mat-icon class="attr-remove" (click)="removeFilterPattern(i)">remove</mat-icon>
              @if (validInfo.errors?.['regex']) {
                <div class="regexError">
                  {{ validInfo.errors?.['regex'] }}
                </div>
              }
            </div>
          }
        }
        <mat-icon class="attr-add" (click)="addFilterPattern()">add</mat-icon>
      </fieldset>
      <fieldset class="like-mat-form-field-outline">
        <legend>{{ i18n.get('extent') }}</legend>
        <mat-checkbox [checked]="layer.extent" (change)="toggleExtent($event.checked)">{{ i18n.get('limitExtent') }}</mat-checkbox>
        <div class="infoText">{{ i18n.get('extentInfo') }}</div>
        @if (layer.extent) {
          <div>
            <div class="extentTitle">
              <div class="extent-field">min x</div>
              <div class="extent-field">min y</div>
              <div class="extent-field">max x</div>
              <div class="extent-field">max y</div>
            </div>
            <div class="extenValue">
              <input class="extent-field" matInput type="number" [(ngModel)]="layer.extent[0]" />
              <input class="extent-field" matInput type="number" [(ngModel)]="layer.extent[1]" />
              <input class="extent-field" matInput type="number" [(ngModel)]="layer.extent[2]" />
              <input class="extent-field" matInput type="number" [(ngModel)]="layer.extent[3]" />
            </div>
          </div>
        }
      </fieldset>
    </div>
  }

  <fieldset class="like-mat-form-field-outline">
    <legend>{{ i18n.get('styleSourceType') }}</legend>
    <mat-radio-group [(ngModel)]="layer.styleSourceType">
      <mat-radio-button value="url">{{ i18n.get('styleSourceTypeUrl') }}</mat-radio-button>
      <mat-radio-button value="text">{{ i18n.get('styleSourceTypeText') }}</mat-radio-button>
    </mat-radio-group>
  </fieldset>
  <fieldset class="like-mat-form-field-outline">
    <legend>{{ i18n.get('styleFormat') }}</legend>
    <mat-radio-group [(ngModel)]="layer.styleFormat">
      <mat-radio-button value="mapbox"
        >{{ i18n.get('styleFormatMapBox') }}
        <a target="_blank" href="https://docs.mapbox.com/style-spec/guides/">{{ i18n.get('styleFormatSpec') }}</a></mat-radio-button
      >
      <mat-radio-button value="olFlat"
        >{{ i18n.get('styleFormatOlFlat') }}
        <a target="_blank" href="https://openlayers.org/en/latest/apidoc/module-ol_style_flat.html">{{
          i18n.get('styleFormatSpec')
        }}</a></mat-radio-button
      >
    </mat-radio-group>
  </fieldset>
  @if (layer.styleFormat === 'mapbox') {
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>{{ i18n.get('styleSourceName') }}</mat-label>
      <input matInput type="url" [(ngModel)]="layer.styleSourceName" />
    </mat-form-field>
  }
  @if (layer.styleSourceType === 'url') {
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>{{ i18n.get('styleSourceUrl') }}</mat-label>
      <input matInput type="url" [(ngModel)]="layer.styleUrl" />
    </mat-form-field>
  }
  @if (layer.styleSourceType === 'text') {
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>{{ i18n.get('styleSourceText') }}</mat-label>
      <textarea matInput [(ngModel)]="layer.styleText"></textarea>
    </mat-form-field>
  }

  <fieldset class="like-mat-form-field-outline">
    <legend>{{ i18n.get('layerSearchable') }}</legend>
    <mat-radio-group [(ngModel)]="layer.searchable">
      <mat-radio-button [value]="false">{{ i18n.get('no') }}</mat-radio-button>
      <mat-radio-button [value]="true">{{ i18n.get('yes') }}</mat-radio-button>
    </mat-radio-group>
  </fieldset>
  @if (layer.searchable) {
    <mat-form-field appearance="outline" floatLabel="always" subscriptSizing="dynamic">
      <mat-label>{{ i18n.get('searchResultLabelMask') }}</mat-label>
      <div class="infoText">
        {{ i18n.get('searchResultLabelMaskInfo') }}<br /><code [innerText]="'${streetName} ${entranceNumber}'"></code>
      </div>
      <input matInput [(ngModel)]="layer.searchResultLabelMask" />
    </mat-form-field>
  }
  @if (layer.searchable) {
    <fieldset class="like-mat-form-field-outline">
      <legend>{{ i18n.get('searchRegExPattern') }}</legend>
      <div class="infoText">
        {{ i18n.get('searchRegExPatternInfo') }}<br /><code
          [innerText]="'(?<streetName>\\p{L}+(?: \\p{L}+)*) (?<entranceNumber>\\d+ ?\\p{L}?)'"
        ></code>
      </div>
      <div class="infoText">{{ i18n.get('searchRegExPatternFullMatch') }} <code>^...$</code></div>
      @if (layer.searchRegExPatterns) {
        <div class="regexTitle">
          <div class="attr-search-regex">RegEx</div>
          <div class="attr-search-options">{{ i18n.get('searchRegExPatternOptionsTitle') }}</div>
        </div>
        @for (regex of layer.searchRegExPatterns; track regex; let i = $index) {
          <div class="searchRegExPatterns">
            <input
              class="attr-search-regex"
              matInput
              [appRegexValidator]="regex[1]"
              #validInfo="ngModel"
              type="text"
              [(ngModel)]="regex[0]"
            />
            <input
              class="attr-search-options"
              matInput
              type="text"
              [(ngModel)]="regex[1]"
              (input)="deferredRevalidate(validInfo)"
              (blur)="validInfo.control.updateValueAndValidity()"
            />
            <mat-icon class="attr-remove" (click)="removePattern(i)">remove</mat-icon>
            @if (validInfo.errors?.['regex']) {
              <div class="regexError">
                {{ validInfo.errors?.['regex'] }}
              </div>
            }
          </div>
        }
      }
      <mat-icon class="attr-add" (click)="addPattern()">add</mat-icon>
    </fieldset>
  }
  @if (layer.searchable) {
    <fieldset class="like-mat-form-field-outline">
      <legend>{{ i18n.get('searchResultGrouping') }}</legend>
      <div class="infoText">{{ i18n.get('searchResultGroupingInfo') }}</div>
      @if (layer.searchResultGroupingFilterFields) {
        @for (dummy of [].constructor(layer.searchResultGroupingFilterFields.length); track dummy; let i = $index) {
          <div class="searchResultGroupingFilterFields">
            <input class="attr-field" matInput type="text" [(ngModel)]="layer.searchResultGroupingFilterFields[i]" />
            <mat-icon class="attr-remove" (click)="removeField(i)">remove</mat-icon>
          </div>
        }
      }
      <mat-icon class="attr-add" (click)="addField()">add</mat-icon>
    </fieldset>
  }
  @if (layer.searchable) {
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>{{ i18n.get('searchMaxResultCount') }}</mat-label>
      <input matInput min="0" type="number" [(ngModel)]="layer.searchMaxResultCount" />
    </mat-form-field>
  }

  <fieldset class="like-mat-form-field-outline">
    <legend>{{ i18n.get('publicSource') }}</legend>
    <mat-checkbox [checked]="layer.public" (change)="layer.public = $event.checked">{{ i18n.get('publicSourceInfo') }}</mat-checkbox>
  </fieldset>
  <mat-form-field appearance="outline" subscriptSizing="dynamic">
    <mat-label>{{ i18n.get('MaxScaleDenominator') }}</mat-label>
    <input matInput min="0" type="number" [(ngModel)]="layer.MaxScaleDenominator" />
  </mat-form-field>
  <mat-form-field appearance="outline" subscriptSizing="dynamic">
    <mat-label>{{ i18n.get('MinScaleDenominator') }}</mat-label>
    <input matInput min="0" type="number" [(ngModel)]="layer.MinScaleDenominator" />
  </mat-form-field>
  <fieldset class="like-mat-form-field-outline">
    <legend>{{ i18n.get('sourceAttribution') }}</legend>
    @if (layer.attribution) {
      <div class="attributionTitle">
        <div class="attr-title">{{ i18n.get('label') }} *</div>
        <div class="attr-url">URL</div>
      </div>
      @for (attr of layer.attribution; track attr; let i = $index) {
        <div class="attribution">
          <input class="attr-title" matInput type="text" [(ngModel)]="attr[0]" />
          <input class="attr-url" matInput type="url" [(ngModel)]="attr[1]" />
          <mat-icon class="attr-remove" (click)="removeAttribution(i)">remove</mat-icon>
        </div>
      }
    }
    <mat-icon class="attr-add" (click)="addAttribution()">add</mat-icon>
  </fieldset>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="ok()">
    {{ i18n.get('ok') }}
  </button>
</div>
