<div mat-dialog-title>{{ i18n.get('editWmsSources') }}</div>
@if (selectGlobalSourceMode) {
  <div mat-dialog-content>
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>{{ i18n.get('layerSource') }}</mat-label>
      <mat-select [(value)]="globalSource">
        @for (item of filteredGlobalSources$ | async; track item) {
          <mat-option [value]="item">
            {{ item.label }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <div class="buttons">
      <button class="buttonMargin" mat-raised-button color="primary" (click)="addSource()" [innerText]="i18n.get('addSource')"></button>
      <button class="buttonMargin" mat-raised-button (click)="selectGlobalSourceMode = false" [innerText]="i18n.get('cancel')"></button>
    </div>
  </div>
} @else {
  <div mat-dialog-content>
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>{{ i18n.get('layerSource') }}</mat-label>
      <mat-select [value]="selectedSource" (selectionChange)="onSelectionChange($event)">
        <mat-option value="_GeoAdmin_" disabled>GeoAdmin</mat-option>
        @if (this.filteredGlobalSources$.value.length > 0) {
          <mat-option value="__SELECT__">{{ i18n.get('selectLayerSource') }}</mat-option>
        }
        <mat-option value="__NEW__">{{ i18n.get('addLayerSource') }}</mat-option>
        @for (item of sources; track item) {
          <mat-option [value]="item">
            {{ item.label }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    @if (selectedSource !== undefined) {
      <div>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>{{ i18n.get('label') }}</mat-label>
          <input matInput type="text" [(ngModel)]="selectedSource.label" [disabled]="!selectedSource.owner" />
        </mat-form-field>
        <fieldset class="like-mat-form-field-outline">
          <legend>{{ i18n.get('publicSource') }}</legend>
          <mat-checkbox
            [checked]="selectedSource.public"
            (change)="selectedSource.public = $event.checked"
            [disabled]="!selectedSource.owner"
            >{{ i18n.get('publicSourceInfo') }}</mat-checkbox
          >
        </fieldset>
        <mat-form-field appearance="outline" subscriptSizing="dynamic">
          <mat-label>{{ i18n.get('layerSourceUrl') }}</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="selectedSource.url"
            (change)="updateFullUrl($event)"
            [disabled]="!selectedSource.owner"
          />
        </mat-form-field>
        <div class="fullUrl">{{ fullUrl }}</div>
        <fieldset class="like-mat-form-field-outline">
          <legend>{{ i18n.get('mapLayerType') }}</legend>
          <mat-radio-group [(ngModel)]="selectedSource.type" (change)="updateFullUrl($event)" [disabled]="!selectedSource.owner">
            <mat-radio-button value="wms">WMS</mat-radio-button>
            <mat-radio-button value="wmts">WMTS</mat-radio-button>
          </mat-radio-group>
        </fieldset>
        <fieldset class="like-mat-form-field-outline">
          <legend>{{ i18n.get('sourceAttribution') }}</legend>
          @if (selectedSource.attribution) {
            <div class="attributionTitle">
              <div class="attr-title">{{ i18n.get('label') }} *</div>
              <div class="attr-url">URL</div>
            </div>
            @for (attr of selectedSource.attribution; track attr; let i = $index) {
              <div class="attribution">
                <input class="attr-title" matInput type="text" [(ngModel)]="attr[0]" [disabled]="!selectedSource.owner" />
                <input class="attr-url" matInput type="url" [(ngModel)]="attr[1]" [disabled]="!selectedSource.owner" />
                <mat-icon class="attr-remove" (click)="removeAttribution(i)">remove</mat-icon>
              </div>
            }
          }
          @if (selectedSource.owner) {
            <mat-icon class="attr-add" (click)="addAttribution()">add</mat-icon>
          }
        </fieldset>
        <div class="buttons">
          <button
            class="buttonMargin"
            mat-raised-button
            color="warn"
            (click)="removeSource()"
            [innerText]="i18n.get('removeSource')"
          ></button>
        </div>
      </div>
    }
  </div>
}
@if (!selectGlobalSourceMode) {
  <div mat-dialog-actions align="end">
    <button mat-raised-button [mat-dialog-close]="null" [innerText]="i18n.get('cancel')"></button>
    <button mat-raised-button color="primary" [mat-dialog-close]="sources">
      {{ i18n.get('ok') }}
    </button>
  </div>
}
