@if (signs.length > 0) {
  <div>
    <h4 aria-level="2">{{ i18n.get('recentlyUsedSigns') }}</h4>
    <div class="wrapper">
      @for (sign of signs; track sign) {
        <button mat-button color="primary" [title]="i18n.getLabelForSign(sign)" (click)="doSelectSign(sign)">
          <img [src]="getImageUrl(sign.src)" />
        </button>
      }
    </div>
  </div>
}
