<form class="container">
  <div class="header">
    <app-recently-used-signs [dialog]="this" (selectSign)="select($event)"> </app-recently-used-signs>

    <div class="filter">
      <mat-form-field appearance="outline" class="flexGrow">
        <input
          name="filter"
          matInput
          [(ngModel)]="filter"
          (ngModelChange)="updateAvailableSigns()"
          placeholder="{{ i18n.get('search') }}"
        />
      </mat-form-field>
      <mat-form-field appearance="outline" class="flexGrow">
        <mat-label>{{ i18n.get('filterByCategory') }}</mat-label>
        <mat-select [(value)]="selected" (selectionChange)="updateAvailableSigns()">
          <mat-option>{{ i18n.get('allCategories') }}</mat-option>
          @for (category of signCategories; track category) {
            <mat-option value="{{ category.name }}">
              <span class="categoryDot" [ngStyle]="{ 'background-color': category.color }"></span>
              {{ i18n.get('sign' + capitalizeFirstLetter(category.name)) }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="overflow-auto">
    <table mat-table [dataSource]="filteredSigns">
      <ng-container matColumnDef="symbol">
        <th mat-header-cell *matHeaderCellDef>{{ i18n.get('symbol') }}</th>
        <td mat-cell *matCellDef="let sign"><img width="25" height="25" [src]="getImageUrl(sign.src)" /></td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ i18n.get('name') }}</th>
        <td mat-cell *matCellDef="let sign">{{ i18n.getLabelForSign(sign) }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>{{ i18n.get('type') }}</th>
        <td mat-cell *matCellDef="let sign">
          <mat-icon aria-hidden="false">
            {{ getIconFromType(sign.type) }}
          </mat-icon>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['symbol', 'name', 'actions']"></tr>
      <tr mat-row class="row" *matRowDef="let row; columns: ['symbol', 'name', 'actions']" (click)="select(row)"></tr>
    </table>
  </div>
</form>
