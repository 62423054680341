<div class="table-container">
  <div class="header">
    <mat-form-field appearance="outline">
      <input
        matInput
        name="protocolFilterString"
        [(ngModel)]="protocolTableDataSource.filter"
        #protocolFilterString="ngModel"
        [placeholder]="i18n.get('csvSearchFor')"
      />
    </mat-form-field>
    <app-projection-selection
      [projectionFormatIndex]="projectionFormatIndex"
      [numerical]="numerical"
      (projectionChanged)="updateProjection($event)"
    ></app-projection-selection>
  </div>
  <table mat-table [dataSource]="protocolTableDataSource" class="mat-elevation-z8 protocol-table" matSort>
    <ng-container matColumnDef="id">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by id">{{ i18n.get('csvID') }}</th>
      <td mat-cell *matCellDef="let element">{{ element.id }}</td>
    </ng-container>
    <ng-container matColumnDef="date">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by date">{{ i18n.get('csvDate') }}</th>
      <td mat-cell *matCellDef="let element">{{ element.date }}</td>
    </ng-container>
    <ng-container matColumnDef="group">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by group">{{ i18n.get('csvGroup') }}</th>
      <td mat-cell *matCellDef="let element">{{ element.group }}</td>
    </ng-container>
    <ng-container matColumnDef="sign">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by signature">{{ i18n.get('csvSignatur') }}</th>
      <td mat-cell *matCellDef="let element">{{ element.sign }}</td>
    </ng-container>
    <ng-container matColumnDef="centroid">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by centroid">{{ i18n.get('csvCentroid') }}</th>
      <td mat-cell *matCellDef="let element" class="centroid" (click)="navigateTo(element)">{{ element.centroid }}</td>
    </ng-container>
    <ng-container matColumnDef="location">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by location">{{ i18n.get('csvLocation') }}</th>
      <td mat-cell *matCellDef="let element">{{ element.location }}</td>
    </ng-container>
    <ng-container matColumnDef="size">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by size">{{ i18n.get('csvSize') }}</th>
      <td mat-cell *matCellDef="let element">{{ element.size }}</td>
    </ng-container>
    <ng-container matColumnDef="label">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by label">{{ i18n.get('csvLabel') }}</th>
      <td mat-cell *matCellDef="let element">{{ element.label }}</td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by description">
        {{ i18n.get('csvDescription') }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.description }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
