var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) for (var prop of __getOwnPropSymbols(b)) {
    if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  }
  return a;
};
var __name = (target, value) => __defProp(target, "name", {
  value,
  configurable: true
});
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = value => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = value => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = x => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/index.ts
import { default as DataTileSource } from "ol/source/DataTile.js";
import TileState from "ol/TileState.js";
import { MVT } from "ol/format.js";
import { default as VectorTileSource } from "ol/source/VectorTile.js";
import { createXYZ, extentFromProjection } from "ol/tilegrid.js";
import { PMTiles } from "pmtiles";
var _PMTilesRasterSource = class _PMTilesRasterSource extends DataTileSource {
  constructor(options) {
    super(__spreadValues(__spreadValues({}, options), {
      state: "loading"
    }));
    this.loadImage = /* @__PURE__ */__name(src => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.addEventListener("load", () => resolve(img));
        img.addEventListener("error", () => reject(new Error("load failed")));
        img.src = src;
      });
    }, "loadImage");
    const p = new PMTiles(options.url);
    p.getHeader().then(h => {
      const projection = options.projection === void 0 ? "EPSG:3857" : options.projection;
      this.tileGrid = options.tileGrid || createXYZ({
        extent: extentFromProjection(projection),
        maxResolution: options.maxResolution,
        minZoom: h.minZoom,
        maxZoom: h.maxZoom,
        tileSize: options.tileSize
      });
      this.setLoader((z, x, y) => __async(this, null, function* () {
        const response = yield p.getZxy(z, x, y);
        if (!response) {
          return new Uint8Array();
        }
        const src = URL.createObjectURL(new Blob([response.data]));
        const image = yield this.loadImage(src);
        URL.revokeObjectURL(src);
        return image;
      }));
      this.setState("ready");
    });
  }
};
__name(_PMTilesRasterSource, "PMTilesRasterSource");
var PMTilesRasterSource = _PMTilesRasterSource;
var _PMTilesVectorSource = class _PMTilesVectorSource extends VectorTileSource {
  constructor(options) {
    super(__spreadValues(__spreadValues({}, options), {
      state: "loading",
      url: "pmtiles://{z}/{x}/{y}",
      format: options.format || new MVT()
    }));
    this.tileLoadFunction = /* @__PURE__ */__name((tile, url) => {
      const vtile = tile;
      const re = new RegExp(/pmtiles:\/\/(\d+)\/(\d+)\/(\d+)/);
      const result = url.match(re);
      if (!(result && result.length >= 4)) {
        throw Error("Could not parse tile URL");
      }
      const z = +result[1];
      const x = +result[2];
      const y = +result[3];
      vtile.setLoader((extent, resolution, projection) => {
        this.pmtiles_.getZxy(z, x, y).then(tile_result => {
          if (tile_result) {
            const format = vtile.getFormat();
            vtile.setFeatures(format.readFeatures(tile_result.data, {
              extent,
              featureProjection: projection
            }));
            vtile.setState(TileState.LOADED);
          } else {
            vtile.setFeatures([]);
            vtile.setState(TileState.EMPTY);
          }
        }).catch(err => {
          vtile.setFeatures([]);
          vtile.setState(TileState.ERROR);
        });
      });
    }, "tileLoadFunction");
    this.pmtiles_ = new PMTiles(options.url);
    this.pmtiles_.getHeader().then(h => {
      const projection = options.projection || "EPSG:3857";
      const extent = options.extent || extentFromProjection(projection);
      this.tileGrid = options.tileGrid || createXYZ({
        extent,
        maxResolution: options.maxResolution,
        maxZoom: options.maxZoom !== void 0 ? options.maxZoom : h.maxZoom,
        minZoom: h.minZoom,
        tileSize: options.tileSize || 512
      });
      this.setTileLoadFunction(this.tileLoadFunction);
      this.setState("ready");
    });
  }
};
__name(_PMTilesVectorSource, "PMTilesVectorSource");
var PMTilesVectorSource = _PMTilesVectorSource;
export { PMTilesRasterSource, PMTilesVectorSource };
