<div mat-dialog-content>
  <mat-form-field appearance="outline">
    <textarea matInput placeholder="{{ i18n.get('yourText') }}" [(ngModel)]="text"></textarea>
  </mat-form-field>
</div>
<mat-dialog-actions align="end">
  <button mat-stroked-button (click)="cancel()">
    {{ i18n.get('cancel') }}
  </button>
  <button mat-raised-button color="primary" (click)="submit()">
    {{ i18n.get('ok') }}
  </button>
</mat-dialog-actions>
